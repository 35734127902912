import React, { useEffect, useState, useMemo, useRef } from "react";
import { CircularProgress, Modal, IconButton, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReportIcon from "@mui/icons-material/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import offenses from "./offenses";
import generalQuery from "../generalQuery";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./BehaviourReporting.css";
import classes from "./classes";
import { font } from "../../src/font";
const BehaviourReporting = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchEsis, setSearchEsis] = useState("");
  const [searchClass, setSearchClass] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentReports, setStudentReports] = useState([]); // Store offense reports
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await generalQuery(
        "select studentnumber, englishname, username, esisclass, phonenumber, grade_level, id, mobile, father, mother from students"
      );
      setStudents(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = students.filter((student) => {
      if (searchEsis) {
        return student.studentnumber.includes(searchEsis);
      } else if (searchClass) {
        return student.esisclass === searchClass;
      }
      return false;
    });
    setFilteredStudents(filtered);
  }, [searchEsis, searchClass, students]);

  const handleEsisChange = (e) => {
    setSearchEsis(e.target.value);
    setSearchClass("");
  };

  const handleClassChange = (e) => {
    setSearchClass(e.target.value);
    setSearchEsis("");
  };

  const sortedStudents = useMemo(() => {
    if (sortConfig.key) {
      return [...filteredStudents].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }
    return filteredStudents;
  }, [filteredStudents, sortConfig]);

  // const handleSort = (key) => {
  //   setSortConfig((prevConfig) => ({
  //     key,
  //     direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
  //   }));
  // };
  function processReports(reports) {
    const levelHierarchy = [
      "Level One",
      "Level Two",
      "Level Three",
      "Level Four",
    ];

    const offenseMap = {};

    reports.forEach((report) => {
      const key = `${report.offenseeng}-${report.offensear}`;

      if (!offenseMap[key]) {
        // Initialize a new unique offense entry
        offenseMap[key] = {
          offenseeng: report.offenseeng,
          offensear: report.offensear,
          level: report.level,
          repetition: 1,
          reporters: [
            {
              reporter_name: report.reporter_name,
              time_stamp: report.time_stamp,
            },
          ],
        };
      } else {
        // Increment repetition count
        offenseMap[key].repetition++;

        // Add reporter and timestamp
        offenseMap[key].reporters.push({
          reporter_name: report.reporter_name,
          time_stamp: report.time_stamp,
        });

        // Increase level if repeated more than once, excluding Level Four
        const currentLevelIndex = levelHierarchy.indexOf(offenseMap[key].level);
        if (currentLevelIndex < levelHierarchy.length - 1) {
          offenseMap[key].level = levelHierarchy[currentLevelIndex + 1];
        }
      }
    });

    // Convert the offenseMap to an array
    return Object.values(offenseMap).map((offense) => {
      return {
        ...offense,
        reporters: offense.reporters
          .map((r) => `${r.reporter_name} (${r.time_stamp})`)
          .join(", "),
      };
    });
  }

  // Usage example

  const handleOpenModal = async (student) => {
    setSelectedStudent(student);
    setOpenModal(true);

    // Fetch offense reports for the selected student
    const reports = await generalQuery(
      `select * from offensesreports where studentnumber = '${student.studentnumber}'`
    );
    const uniqueReports = processReports(reports);
    console.log(uniqueReports);
    setStudentReports(reports);
  };

  const handleOpenViewModal = async (student) => {
    setSelectedStudent(student);
    setOpenViewModal(true);

    // Fetch offense reports for the selected student
    const reports = await generalQuery(
      `select * from offensesreports where studentnumber = '${student.studentnumber}'`
    );
    const uniqueReports = processReports(reports);
    console.log(uniqueReports);
    setStudentReports(reports);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedStudent(null);
    setStudentReports([]);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedStudent(null);
    setStudentReports([]);
  };

  const handlePrintReports = () => {
    const doc = new jsPDF({ orientation: "landscape" }); // Landscape orientation
    doc.addFileToVFS("IBMPlexSansArabic-Medium.ttf", font);
    doc.addFont("IBMPlexSansArabic-Medium.ttf", "IBMPlexSansArabic", "normal");
    doc.setFont("IBMPlexSansArabic", "normal");
    doc.setFontSize(12);

    // Title
    doc.text(`Offense Reports for ${selectedStudent?.englishname}`, 10, 10);

    // Table Data
    doc.autoTable({
      head: [["Offense (English / Arabic)", "Level", "Reporter", "Timestamp"]],
      body: studentReports.map((report) => [
        `${report.offenseeng}\n${report.offensear}`, // Combine English and Arabic offenses
        report.level,
        report.reporter_name,
        new Date(report.time_stamp).toLocaleString(),
      ]),
      headStyles: { fillColor: [0, 0, 255], textColor: [255, 255, 255] }, // Blue background, white text
      styles: {
        font: "IBMPlexSansArabic", // Use Arabic font globally
      },
      theme: "grid",
      margin: { top: 20 },
    });

    // Save the PDF
    doc.save(`OffenseReports_${selectedStudent?.studentnumber}.pdf`);
  };

  const handleReportOffense = (offense) => {
    if (
      window.confirm(
        `Are you sure you want to report this offense for ${selectedStudent.englishname}?`
      )
    ) {
      const reportData = {
        time_stamp: new Date().toISOString(),
        report_id: Math.random().toString(36).substr(2, 9),
        reporter_name: sessionStorage.getItem("teachername"),
        code: offense.code,
        level: offense.level,
        offenseEng: offense.offenseEng,
        offenseAr: offense.offenseAr,
        ...selectedStudent,
      };
      generalQuery(
        `insert into offensesreports (time_stamp, report_id, reporter_name, code, level, offenseEng, offenseAr, studentnumber, englishname, username, esisclass, phonenumber, grade_level, id, mobile, father, mother) VALUES ('${reportData.time_stamp}', '${reportData.report_id}', '${reportData.reporter_name}', '${reportData.code}', '${reportData.level}', '${reportData.offenseEng}', '${reportData.offenseAr}', '${reportData.studentnumber}', '${reportData.englishname}', '${reportData.username}', '${reportData.esisclass}', '${reportData.phonenumber}', '${reportData.grade_level}', '${reportData.id}', '${reportData.mobile}', '${reportData.father}', '${reportData.mother}')`
      );
      handleCloseModal();
      alert("Report submitted successfully.");
    }
  };
  let offensRepot = (
    <div>
      {" "}
      {studentReports.length > 0 ? (
        <table className="attendance-table">
          <thead>
            <tr>
              <th>Offense</th>
              <th>Level</th>
              <th>Reporter</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {studentReports.map((report) => (
              <tr key={report.report_id}>
                <td>
                  {`${report.offenseeng}`}
                  <br />
                  {`${report.offensear}`}
                </td>
                <td>{report.level}</td>
                <td>{report.reporter_name}</td>
                <td>{new Date(report.time_stamp).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No offense reports found for this student.</p>
      )}
    </div>
  );
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="BehaviourReporting">
      <div className="search-container">
        <input
          className="select"
          type="text"
          placeholder="Search by esis"
          value={searchEsis}
          onChange={handleEsisChange}
        />
        <select
          className="select"
          value={searchClass}
          onChange={handleClassChange}
        >
          <option value="">Select Class</option>
          {classes.map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </select>
      </div>

      <div className="table-container">
        <table className="student-table">
          <thead>
            <tr>
              <th>Student Number</th>
              <th>English Name</th>
              <th>Action</th>
              <th>Reports</th>
            </tr>
          </thead>
          <tbody>
            {sortedStudents.map((student) => (
              <tr key={student.studentnumber}>
                <td>{student.studentnumber}</td>
                <td>{student.englishname}</td>
                <td>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenModal(student)}
                  >
                    <ReportIcon />
                  </IconButton>
                </td>
                <td>
                  <IconButton
                    color="secondary"
                    onClick={() => handleOpenViewModal(student)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal open={openModal} onClose={handleCloseModal}>
        <div className="modal-content">
          <h2>{selectedStudent?.englishname}</h2>
          <div className="offense-grid">
            <div className="level-box level-one">
              <h4>Level One Offenses</h4>
              <div className="offense-icons">
                {offenses
                  .filter((offense) => offense.level === "Level One")
                  .map((offense) => (
                    <Tooltip
                      key={offense.code}
                      title={`${offense.offenseEng} / ${offense.offenseAr}`}
                      arrow
                    >
                      <IconButton onClick={() => handleReportOffense(offense)}>
                        {offense.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
              </div>
            </div>

            <div className="level-box level-two">
              <h4>Level Two Offenses</h4>
              <div className="offense-icons">
                {offenses
                  .filter((offense) => offense.level === "Level Two")
                  .map((offense) => (
                    <Tooltip
                      key={offense.code}
                      title={`${offense.offenseEng} / ${offense.offenseAr}`}
                      arrow
                    >
                      <IconButton onClick={() => handleReportOffense(offense)}>
                        {offense.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
              </div>
            </div>

            <div className="level-box level-three">
              <h4>Level Three Offenses</h4>
              <div className="offense-icons">
                {offenses
                  .filter((offense) => offense.level === "Level Three")
                  .map((offense) => (
                    <Tooltip
                      key={offense.code}
                      title={`${offense.offenseEng} / ${offense.offenseAr}`}
                      arrow
                    >
                      <IconButton onClick={() => handleReportOffense(offense)}>
                        {offense.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
              </div>
            </div>

            <div className="level-box level-four">
              <h4>Level Four Offenses</h4>
              <div className="offense-icons">
                {offenses
                  .filter((offense) => offense.level === "Level Four")
                  .map((offense) => (
                    <Tooltip
                      key={offense.code}
                      title={`${offense.offenseEng} / ${offense.offenseAr}`}
                      arrow
                    >
                      <IconButton onClick={() => handleReportOffense(offense)}>
                        {offense.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
              </div>
            </div>
          </div>

          {offensRepot}
        </div>
      </Modal>
      <Modal open={openViewModal} onClose={handleCloseViewModal}>
        <div className="modal-content">
          <h2>Offense Reports</h2>
          <h3>{selectedStudent?.englishname}</h3>

          <button
            className="print-pdf-btn"
            onClick={handlePrintReports}
            style={{
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              padding: "10px 15px",
              fontSize: "14px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginBottom: "15px",
              width: "7%",
            }}
          >
            <span style={{ fontSize: "18px" }}>&#128438;</span>{" "}
            {/* Print Icon */}
            Print
          </button>

          {offensRepot}
        </div>
      </Modal>
    </div>
  );
};

export default BehaviourReporting;
