import React from "react";
import blockedCards from "./blockedCards";
import BlockedCardsReveald from "./BlockedCardsReveald";
const ReportCard = () => {
  const studentId = sessionStorage.getItem("esis");
  const pdfUrl = `${process.env.PUBLIC_URL}/Reportst32324/${studentId}.pdf`;
  const pdfUrl2 = `${process.env.PUBLIC_URL}/reportsG8T12425/${studentId}.pdf`;

  return (
    <div>
      {/* Example: Providing a download link */}
      {/* <a href={pdfUrl} target="_blank"  onClick={(e) => e.preventDefault()}>
        Download Report Card
      </a> */}
      {/* Or if you want to display it in an iframe */}
      {/* { (sessionStorage.getItem("grade")?.slice(1,2)==='7'||sessionStorage.getItem("grade")?.slice(1,2)==='8')?<iframe src={pdfUrl} width="100%" height="600px" title="Term 1 Report"></iframe>:"Not issued yet!"} */}
      {sessionStorage.getItem("grade")?.slice(1, 2) === "7" ||
      sessionStorage.getItem("grade")?.slice(1, 2) === "8" ? (
        <>
          {blockedCards.includes(sessionStorage.getItem("esis")) ? (
            <BlockedCardsReveald />
          ) : (
            <iframe
              src={pdfUrl2}
              width="100%"
              height="600px"
              title="End of Year Report"
            ></iframe>
          )}
        </>
      ) : (
        <>
          {blockedCards.includes(sessionStorage.getItem("esis")) ? (
            <BlockedCardsReveald />
          ) : (
            <iframe
              src={pdfUrl}
              width="100%"
              height="600px"
              title="Semester 1 Report"
            ></iframe>
          )}
        </>
      )}
    </div>
  );
};

export default ReportCard;
