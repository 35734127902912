import React, { useState } from "react";
import readXlsxFile from "read-excel-file";
import "./Uploader.css";

const Uploader = ({ addDataset }) => {
  const [file, setFile] = useState(null);
  const [dataType, setDataType] = useState("teachers");

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  const handleUploadClick = () => {
    if (!file) {
      alert("Please select a file to upload");
      return;
    }
    readXlsxFile(file).then((rows) => {
      addDataset(dataType, rows);
    });
  };

  return (
    <div className="Uploader">
      <h2>Upload Form</h2>
      <div className="form-group">
        <label htmlFor="file">Select a file to upload:</label>
        <input type="file" id="file" onChange={handleFileUpload} />
      </div>
      <div className="form-group">
        <label htmlFor="dataType">Select the data type:</label>
        <select id="dataType" value={dataType} onChange={handleDataTypeChange}>
          <option value="Teachers">Teachers</option>
          <option value="Lessons">Lessons</option>
          <option value="Rooms">Rooms</option>
          <option value="Periods">Periods</option>
        </select>
      </div>
      <button onClick={handleUploadClick}>Upload</button>
    </div>
  );
};

export default Uploader;
