import React, { useState, useEffect } from "react";
import generalQuery from "../generalQuery";
import "./SchoolOverall.css";
import { CircularProgress } from "@mui/material";
import Portal from "../views/Portal";
import ClassMapPerformance from "./ClassMapPerformance";

const SchoolOverall = () => {
  const [data, setData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const subjects = ["Language Usage", "Reading", "Math K-12", "Science K-12"];
  const [showPortalView, setShowPortalView] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");

  function removeNullValues(obj) {
    for (const key in obj) {
      if (obj[key] === "null") {
        delete obj[key];
      }
    }
    return obj;
  }
  useEffect(() => {
    generalQuery(
      "select class, course,FallToSpringProjectedGrowth, FallToSpringObservedGrowth,FallToSpringMetProjectedGrowth from mapspring2024 order by class"
    ).then((results) => {
      
      let classesArr = [];
      results.forEach((e) => {
        if (!classesArr.includes(e.class)) {
          classesArr.push(e.class);
        }
      });
      setData(results);
      setClasses(classesArr);
      setLoading(false);
    });
  }, []);
  const getRatio = (sub, c) => {
    let classData = data.filter((d) => d.class === c && d.course === sub);
    let filteredData = data.filter(
      (d) =>
        d.class === c &&
        d.course === sub &&
        (d.falltospringmetprojectedgrowth === "Yes" ||
          d.falltospringmetprojectedgrowth === "Yes*")
    );
    return Math.ceil((filteredData.length * 100) / classData.length);
  };
  const getColor = (val) => {
    if (val < 30) {
      return "level_1";
    }
    if (val >= 30 && val < 50) {
      return "level_2";
    }
    if (val >= 50 && val < 70) {
      return "level_3";
    }
    if (val >= 70 && val < 90) {
      return "level_3";
    }
    if (val >= 90) {
      return "level_4";
    }
  };
  if (loading) {
    return (
      <div className="container">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="container">
        <div
          style={{
            width: "70%",
          }}
        >
          <h3>Spring 23-24</h3>
          <table className="heatmap-table">
            <thead>
              <tr>
                <th></th>
                <th>Language Usage</th>
                <th>Reading</th>
                <th>Math K-12</th>
                <th>Science K-12</th>
              </tr>
            </thead>
            <tbody>
              {classes.map((c, index) => {
                return (
                  <tr key={index}>
                    <th>{c}</th>
                    {subjects.map((s, ind) => {
                      return (
                        <td key={ind} className={getColor(getRatio(s, c))}>
                          <p
                            href="#"
                            onClick={(e) => {
                              // e.preventDefault();
                              // setSelectedClass(c);
                              // setSelectedCourse(s);
                              // setShowPortalView(true);
                              // generalQuery(
                              //   `select * from mapspring23 where class24='${c}' and course='${s}' order by testritscore`
                              // ).then((results) => {
                              //   results.forEach((r) => {
                              //     console.log(removeNullValues(r));
                              //     setShowPortalView(true);
                              //   });
                              // });
                            }}
                          >
                            {getRatio(s, c)}%
                          </p>
                        </td>
                      );
                    })}
                    {/* <td>Incomplete</td>
                    <td>Incomplete</td>
                    <td>Incomplete</td>
                    <td>Incomplete</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {showPortalView ? (
        <Portal>
          <div style={{ display: "flex", flexFlow: "row-reverse" }}>
            <button
              id="closeBtn"
              onClick={() => {
                setShowPortalView(false);
              }}
            >
              X
            </button>
          </div>
          <ClassMapPerformance
            selectedClass={selectedClass}
            selectedCourse={selectedCourse}
          />
        </Portal>
      ) : (
        ""
      )}
    </div>
  );
};

export default SchoolOverall;
{
  /* <tr>
<th>Class A</th>
<td class="heat-cell heat-green">95%</td>
<td class="heat-cell heat-green">92%</td>
<td class="heat-cell heat-red-dark">83%</td>
<td class="heat-cell heat-red-light">78%</td>
<td class="heat-cell heat-green">95%</td>
<td class="heat-cell heat-green">92%</td>
<td class="heat-cell heat-red-dark">83%</td>
<td class="heat-cell heat-red-light">78%</td>
</tr> */
}
