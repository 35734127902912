import jsPDF from "jspdf";
import classListHead from "./classListHead.png";
import { font } from "../../font";
export const listPdfgenerate = (list, lang) => {
  console.log(list);
  let arr = [];
  list.forEach((e) => {
    if (arr.indexOf(e.homroom) === -1) {
      arr.push(e.homroom);
    }
  });
  var doc = new jsPDF();
  doc.addFileToVFS("IBMPlexSansArabic-Medium-normal.ttf", font);
  doc.addFont(
    "IBMPlexSansArabic-Medium-normal.ttf",
    "IBMPlexSansArabic-Medium",
    "normal"
  );
  doc.setFont("IBMPlexSansArabic-Medium");
  doc.setFontSize(15);

  const iterateOverClasses = (listToprint) => {
    doc.setFontSize(10);
    doc.text(new Date().toLocaleDateString(), 10, 30);
    var width = doc.internal.pageSize.getWidth();
    doc.addImage(classListHead, 5, 5, 200, 18);
    doc.text(listToprint[0].homroom, 100, 32);
    doc.setLineWidth(1);
    doc.line(5, 40, width - 5, 40);
    doc.line(5, 40, 5, 55 + listToprint.length * 7.5);
    doc.line(width - 5, 40, width - 5, 55 + listToprint.length * 7.5);
    doc.setLineWidth(0.1);
    doc.line(5, 55, width - 5, 55);
    doc.setFontSize(10);

    if (lang === "ar") {
      for (var i = 1; i <= listToprint.length; i++) {
        if (i === listToprint.length) {
          doc.setLineWidth(1);
        } else {
          doc.setLineWidth(0.1);
        }
        doc.line(5, 55 + i * 7.5, width - 5, 55 + i * 7.5);
        doc.text(i.toString(), width - 18, 52.5 + i * 7.5);
        doc.text(listToprint[i - 1].studentnumber, width - 40, 52.5 + i * 7.5);
        doc.text(
          listToprint[i - 1].username,
          width - 50,
          52.5 + i * 7.5,
          "right"
        );
        if (
          listToprint[i - 1].comment !== "null" &&
          listToprint[i - 1].comment !== null
        ) {
          doc.text(listToprint[i - 1].comment, 25, 52.5 + i * 7.5);
        }
      }
      doc.line(width - 25, 40, width - 25, 55 + listToprint.length * 7.5);
      doc.line(width - 45, 40, width - 45, 55 + listToprint.length * 7.5);
      doc.line(width - 130, 40, width - 130, 55 + listToprint.length * 7.5);
      doc.text("م", width - 17, 47, "right");
      doc.text("الايسيس", width - 30, 47, "right");
      doc.text("الاســـــم", width - 80, 47, "right");
      doc.text("ملاحظــــــات", 50, 47, "right");
    } else {
      for (i = 1; i <= listToprint.length; i++) {
        if (i === listToprint.length) {
          doc.setLineWidth(1);
        } else {
          doc.setLineWidth(0.1);
        }
        doc.line(5, 55 + i * 7.5, width - 5, 55 + i * 7.5);
        doc.text(i.toString(), 13, 52.5 + i * 7.5);
        doc.text(listToprint[i - 1].studentnumber, 28, 52.5 + i * 7.5);
        doc.text(listToprint[i - 1].englishname, 46, 52.5 + i * 7.5);
        if (
          listToprint[i - 1].comment !== "null" &&
          listToprint[i - 1].comment !== null
        ) {
          doc.text(listToprint[i - 1].comment, 153, 52.5 + i * 7.5);
        }
      }
      doc.line(25, 40, 25, 55 + listToprint.length * 7.5);
      doc.line(45, 40, 45, 55 + listToprint.length * 7.5);
      doc.line(150, 40, 150, 55 + listToprint.length * 7.5);
      doc.text("Notes", 170, 47);
      doc.text("eSIS", 30, 47);
      doc.text("Name", 80, 47);
      doc.text("Serial #", 10, 47);
    }
  };

  if (arr.length === 1) {
    if (lang === "en") {
      iterateOverClasses(
        list.sort((a, b) => (a.englishname > b.englishname ? 1 : -1))
      );
    } else {
      iterateOverClasses(
        list.sort((a, b) => (a.username > b.username ? 1 : -1))
      );
    }
    doc.save(list[0].homroom);
  } else {
    if (lang === "en") {
      iterateOverClasses(
        list
          .filter((l) => l.homroom === arr[0])
          .sort((a, b) => (a.englishname > b.englishname ? 1 : -1))
      );
    } else {
      iterateOverClasses(
        list
          .filter((l) => l.homroom === arr[0])
          .sort((a, b) => (a.username > b.username ? 1 : -1))
      );
    }
    arr.shift();
    arr.forEach((a) => {
      doc.addPage();
      doc.setFontSize(15);
      if (lang === "en") {
        iterateOverClasses(
          list
            .filter((l) => l.homroom === a)
            .sort((a, b) => (a.englishname > b.englishname ? 1 : -1))
        );
      } else {
        iterateOverClasses(
          list
            .filter((l) => l.homroom === a)
            .sort((a, b) => (a.username > b.username ? 1 : -1))
        );
      }
    });
    doc.save("Class Lists");
  }
};
