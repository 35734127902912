import AccessTimeIcon from "@mui/icons-material/AccessTime"; // For lateness
import ScheduleIcon from "@mui/icons-material/Schedule"; // For missing class
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer"; // For non-compliance with PE uniform
import RuleIcon from "@mui/icons-material/Rule"; // For not following rules
import BookIcon from "@mui/icons-material/Book"; // For not bringing resources
import FastfoodIcon from "@mui/icons-material/Fastfood"; // For eating in class
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate"; // For incomplete homework
import GamepadIcon from "@mui/icons-material/Gamepad"; // For misuse of digital devices
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices"; // For other misconducts

// Level Two Offenses
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom"; // For leaving or entering without permission
import EventBusyIcon from "@mui/icons-material/EventBusy"; // For missing mandatory activities
import WarningAmberIcon from "@mui/icons-material/WarningAmber"; // For inciting quarrels
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"; // For cultural violations
import ChairAltIcon from "@mui/icons-material/ChairAlt"; // For damaging furniture
import SmartphoneIcon from "@mui/icons-material/Smartphone"; // For unauthorized mobile use
import VoiceOverOffIcon from "@mui/icons-material/VoiceOverOff"; // For verbal abuse
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms"; // For tobacco use
import SearchOffIcon from "@mui/icons-material/SearchOff"; // For refusing inspection

// Level Three Offenses
import PeopleIcon from "@mui/icons-material/People"; // For bullying
import PlagiarismIcon from "@mui/icons-material/Plagiarism"; // For academic dishonesty
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; // For leaving school without permission
import EngineeringIcon from "@mui/icons-material/Engineering"; // For destruction or vandalism
import BusAlertIcon from "@mui/icons-material/BusAlert"; // For bus-related offenses

// Level Four Offenses
import GavelIcon from "@mui/icons-material/Gavel"; // For unlawful communication
import ShieldIcon from "@mui/icons-material/Shield"; // For weapon possession
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly"; // For sexual assault
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement"; // For assault
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"; // For theft
import CameraAltIcon from "@mui/icons-material/CameraAlt"; // For unlawful capturing or distribution
import QuizIcon from "@mui/icons-material/Quiz"; // For exam-related offenses
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"; // For arson
import PersonOffIcon from "@mui/icons-material/PersonOff"; // For insulting political, religious, or social figures
import BalanceIcon from "@mui/icons-material/Balance"; // Ensure to import this icon

const offenses = [
  // Level One Offenses
  {
    code: "L11",
    level: "Level One",
    offenseEng:
      "Being repeatedly late to the morning assembly or failing to participate without an acceptable excuse",
    offenseAr: "التأخر المتكرر عن الطابور الصباحي أو عدم المشاركة فيه دون عذر مقبول",
    icon: <AccessTimeIcon />,
  },
  {
    code: "L12",
    level: "Level One",
    offenseEng:
      "Failing to attend classes on time repeatedly without an acceptable excuse",
    offenseAr: "عدم حضور الحصص في الوقت المحدد بشكل متكرر دون عذر مقبول",
    icon: <ScheduleIcon />,
  },
  {
    code: "L13",
    level: "Level One",
    offenseEng:
      "Non-compliance with the school uniform (regular or PE) without an acceptable excuse",
    offenseAr: "عدم الالتزام بالزي المدرسي (الرسمي أو الرياضي) دون عذر مقبول",
    icon: <SportsSoccerIcon />,
  },
  {
    code: "L14",
    level: "Level One",
    offenseEng:
      "Not following the school’s rules as stated in the Student Code of Conduct, both inside and outside the classroom",
    offenseAr: "عدم اتباع قوانين المدرسة كما هو مذكور في ميثاق سلوك الطلاب، داخل الفصل وخارجه",
    icon: <RuleIcon />,
  },
  {
    code: "L15",
    level: "Level One",
    offenseEng:
      "Not bringing books and other resources for school without an acceptable excuse",
    offenseAr: "عدم إحضار الكتب والمواد الدراسية الأخرى دون عذر مقبول",
    icon: <BookIcon />,
  },
  {
    code: "L16",
    level: "Level One",
    offenseEng:
      "Sleeping or eating during class time or during the morning assembly without justification or permission",
    offenseAr: "النوم أو الأكل أثناء الحصص أو الطابور الصباحي بدون مبرر أو إذن",
    icon: <FastfoodIcon />,
  },
  {
    code: "L17",
    level: "Level One",
    offenseEng:
      "Not complying with the completion of homework and assignments in a timely manner",
    offenseAr: "عدم الالتزام بإتمام الواجبات المنزلية والتكليفات في الوقت المحدد",
    icon: <AssignmentLateIcon />,
  },
  {
    code: "L18",
    level: "Level One",
    offenseEng:
      "Misuse of digital devices in school (e.g., playing games, viewing social media)",
    offenseAr: "سوء استخدام الأجهزة الرقمية في المدرسة (مثل اللعب بالألعاب، استخدام وسائل التواصل الاجتماعي)",
    icon: <GamepadIcon />,
  },
  {
    code: "L19",
    level: "Level One",
    offenseEng: "Any other forms of misconduct similar to the above",
    offenseAr: "أي أشكال أخرى من سوء السلوك مشابهة لما سبق",
    icon: <MiscellaneousServicesIcon />,
  },

  // Level Two Offenses
  {
    code: "L21",
    level: "Level Two",
    offenseEng: "Failing to attend school without an acceptable excuse",
    offenseAr: "عدم الحضور إلى المدرسة بدون عذر مقبول",
    icon: <EventBusyIcon />,
  },
  {
    code: "L22",
    level: "Level Two",
    offenseEng:
      "Leaving or entering the classroom during class time without permission",
    offenseAr: "الخروج أو الدخول إلى الفصل أثناء وقت الحصة بدون إذن",
    icon: <NoMeetingRoomIcon />,
  },
  {
    code: "L23",
    level: "Level Two",
    offenseEng:
      "Not attending mandatory school activities and events without an acceptable excuse",
    offenseAr: "عدم حضور الأنشطة المدرسية والفعاليات الإلزامية دون عذر مقبول",
    icon: <EventBusyIcon />,
  },
  {
    code: "L24",
    level: "Level Two",
    offenseEng: "Inciting quarrels, threatening, or intimidating peers in the school",
    offenseAr: "إثارة الشجار أو تهديد أو تخويف الزملاء في المدرسة",
    icon: <WarningAmberIcon />,
  },
  {
    code: "L25",
    level: "Level Two",
    offenseEng:
      "Acting or appearing in a manner that contradicts cultural policies",
    offenseAr: "التصرف أو الظهور بطريقة تتعارض مع السياسات الثقافية",
    icon: <EmojiPeopleIcon />,
  },
  {
    code: "L26",
    level: "Level Two",
    offenseEng: "Causing minor damage to school or bus furniture",
    offenseAr: "التسبب في تلف طفيف بأثاث المدرسة أو الحافلة",
    icon: <ChairAltIcon />,
  },
  {
    code: "L27",
    level: "Level Two",
    offenseEng: "Using mobile phones at school without permission",
    offenseAr: "استخدام الهواتف المحمولة في المدرسة بدون إذن",
    icon: <SmartphoneIcon />,
  },
  {
    code: "L28",
    level: "Level Two",
    offenseEng:
      "Verbally abusing or insulting any member of the school community",
    offenseAr: "الإساءة اللفظية أو إهانة أي عضو من أعضاء مجتمع المدرسة",
    icon: <VoiceOverOffIcon />,
  },
  {
    code: "L29",
    level: "Level Two",
    offenseEng:
      "Using, promoting, possessing, or distributing tobacco products on school premises",
    offenseAr: "استخدام أو ترويج أو امتلاك أو توزيع منتجات التبغ في المدرسة",
    icon: <SmokingRoomsIcon />,
  },
  {
    code: "L210",
    level: "Level Two",
    offenseEng:
      "Refusing to respond to inspection instructions or to hand over banned items",
    offenseAr: "رفض الاستجابة لتعليمات التفتيش أو تسليم العناصر المحظورة",
    icon: <SearchOffIcon />,
  },

  // Level Three Offenses
  {
    code: "L31",
    level: "Level Three",
    offenseEng:
      "Bullying, intimidation, harassment, and/or abuse of members of the school community",
    offenseAr: "التنمر أو التحرش أو إساءة معاملة أعضاء مجتمع المدرسة",
    icon: <PeopleIcon />,
  },
  {
    code: "L32",
    level: "Level Three",
    offenseEng: "Academic dishonesty/plagiarism",
    offenseAr: "عدم الأمانة الأكاديمية/الانتحال",
    icon: <PlagiarismIcon />,
  },
  {
    code: "L33",
    level: "Level Three",
    offenseEng: "Leaving the school premises without permission",
    offenseAr: "مغادرة مبنى المدرسة بدون إذن",
    icon: <ExitToAppIcon />,
  },
  {
    code: "L34",
    level: "Level Three",
    offenseEng: "Seizure, destruction, and/or vandalism of school property",
    offenseAr: "الاستيلاء على ممتلكات المدرسة أو تدميرها",
    icon: <EngineeringIcon />,
  },
  {
    code: "L35",
    level: "Level Three",
    offenseEng:
      "Destruction of the school bus or causing harm to the driver or other users",
    offenseAr:
      "تدمير الحافلة المدرسية أو التسبب في إلحاق الضرر بالسائق أو الآخرين",
    icon: <BusAlertIcon />,
  },

  // Level Four Offenses
  {
    code: "L41",
    level: "Level Four",
    offenseEng: "Using communication for unlawful or immoral purposes",
    offenseAr: "استخدام وسائل الاتصال لأغراض غير قانونية أو غير أخلاقية",
    icon: <GavelIcon />,
  },
  {
    code: "L42",
    level: "Level Four",
    offenseEng: "Possessing weapons on school premises",
    offenseAr: "حيازة أسلحة في المدرسة",
    icon: <ShieldIcon />,
  },
  {
    code: "L43",
    level: "Level Four",
    offenseEng: "Committing sexual assault, including harassment, in school",
    offenseAr: "ارتكاب اعتداء جنسي، بما في ذلك التحرش، في المدرسة",
    icon: <BalanceIcon />,
  },
  {
    code: "L44",
    level: "Level Four",
    offenseEng: "Assaulting others and causing injury",
    offenseAr: "الاعتداء على الآخرين مما يتسبب في إصابتهم",
    icon: <SelfImprovementIcon />,
  },
  {
    code: "L45",
    level: "Level Four",
    offenseEng: "Premeditated theft or engaging in its cover-up",
    offenseAr: "السرقة المسبقة التخطيط أو المشاركة في إخفائها",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    code: "L46",
    level: "Level Four",
    offenseEng: "Capturing or distributing information with unlawful content",
    offenseAr: "التقاط أو توزيع محتوى غير قانوني",
    icon: <CameraAltIcon />,
  },
  {
    code: "L47",
    level: "Level Four",
    offenseEng: "Leaking exam questions or engaging in related activities",
    offenseAr: "تسريب أسئلة الامتحانات أو المشاركة في الأنشطة ذات الصلة",
    icon: <QuizIcon />,
  },
  {
    code: "L48",
    level: "Level Four",
    offenseEng: "Setting fire to the school premises",
    offenseAr: "إشعال النار في مبنى المدرسة",
    icon: <LocalFireDepartmentIcon />,
  },
  {
    code: "L49",
    level: "Level Four",
    offenseEng: "Insulting political, religious, or social figures",
    offenseAr: "إهانة الشخصيات السياسية أو الدينية أو الاجتماعية",
    icon: <PersonOffIcon />,
  },
];

export default offenses;
