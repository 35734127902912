import React, { useState, useEffect } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "./AttendanceDashboard.css";
import generalQuery from "../generalQuery";
import { CircularProgress } from "@mui/material";

Chart.register(...registerables);
const classData = [
  { class: "G8-A", grade: 8, pod: "A" },
  { class: "G8-B", grade: 8, pod: "A" },
  { class: "G8-B_1", grade: 8, pod: "A" },
  { class: "G8-B_2", grade: 8, pod: "A" },
  { class: "G8-C", grade: 8, pod: "A" },
  { class: "G8-D", grade: 8, pod: "A" },
  { class: "G8-E", grade: 8, pod: "B" },
  { class: "G8-F", grade: 8, pod: "B" },
  { class: "G8-F_1", grade: 8, pod: "B" },
  { class: "G8-F_2", grade: 8, pod: "B" },
  { class: "G8-G", grade: 8, pod: "B" },
  { class: "G8-H", grade: 8, pod: "C" },
  { class: "G8-I", grade: 8, pod: "C" },
  { class: "G8-I_1", grade: 8, pod: "C" },
  { class: "G8-I_2", grade: 8, pod: "C" },
  { class: "G8-J", grade: 8, pod: "C" },
  { class: "G8-J_1", grade: 8, pod: "C" },
  { class: "G8-J_2", grade: 8, pod: "C" },
  { class: "G10-A", grade: 10, pod: "D" },
  { class: "G10-B", grade: 10, pod: "D" },
  { class: "G10-C", grade: 10, pod: "D" },
  { class: "G10-D", grade: 10, pod: "D" },
  { class: "G10-E", grade: 10, pod: "E" },
  { class: "G10-F", grade: 10, pod: "E" },
  { class: "G10-G", grade: 10, pod: "E" },
  { class: "G10-H", grade: 10, pod: "E" },
  { class: "G9-A", grade: 9, pod: "F" },
  { class: "G9-B", grade: 9, pod: "F" },
  { class: "G9-C", grade: 9, pod: "F" },
  { class: "G9-D", grade: 9, pod: "F" },
  { class: "G9-E", grade: 9, pod: "G" },
  { class: "G9-F", grade: 9, pod: "G" },
  { class: "G9-G", grade: 9, pod: "G" },
  { class: "G9-H", grade: 9, pod: "G" },
  { class: "G9-I", grade: 9, pod: "H" },
  { class: "G9-J", grade: 9, pod: "H" },
  { class: "G9-K", grade: 9, pod: "H" },
  { class: "G11-A", grade: 11, pod: "I" },
  { class: "G11-B", grade: 11, pod: "I" },
  { class: "G11-C", grade: 11, pod: "I" },
  { class: "G11-D", grade: 11, pod: "J" },
  { class: "G11-E", grade: 11, pod: "J" },
  { class: "G11-F", grade: 11, pod: "J" },
];

const podColors = [
  "rgba(255, 99, 132, 0.6)", // Red for Pod A
  "rgba(54, 162, 235, 0.6)", // Blue for Pod B
  "rgba(75, 192, 192, 0.6)", // Green for Pod C
  "rgba(255, 206, 86, 0.6)", // Yellow for Pod D
  "rgba(153, 102, 255, 0.6)", // Purple for Pod E
  "rgba(255, 159, 64, 0.6)", // Orange for Pod F
  "rgba(99, 255, 132, 0.6)", // Light Green for Pod G
  "rgba(199, 132, 255, 0.6)", // Light Purple for Pod H
  "rgba(132, 255, 255, 0.6)", // Cyan for Pod I
  "rgba(255, 132, 132, 0.6)", // Light Red for Pod J
];

const AttendanceDashboard = ({ passedDate, refresher }) => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [doughnutData, setDoughnutData] = useState(null);
  function extractDay(dateString) {
    // Extract year, month, and day from the string
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    // Create a Date object (month is 0-indexed in JavaScript)
    const date = new Date(year, month - 1, day);

    // Extract the day
    return date.getDate();
  }

  const dateString = "20231006";
  const day = extractDay(dateString);

  useEffect(() => {
    const tableName = `zatt${passedDate}`;

    // Fetch attendance data from the table
    generalQuery(`SELECT * FROM ${tableName}`)
      .then((data) => {
        if (!data || !Array.isArray(data)) {
          console.error("No valid data returned from query:", data);
          return; // Exit if data is not valid
        }
        let processedData = data.map((student) => {
          const p1 = parseFloat(student.p1);
          const p2 = parseFloat(student.p2);
          const p3 = parseFloat(student.p3);
          const p4 = parseFloat(student.p4);
          const p5 = parseFloat(student.p5);
          const p6 = parseFloat(student.p6);

          // Handle NaN by defaulting to 0
          const p1Value = isNaN(p1) ? 0 : p1;
          const p2Value = isNaN(p2) ? 0 : p2;
          const p3Value = isNaN(p3) ? 0 : p3;
          const p4Value = isNaN(p4) ? 0 : p4;
          const p5Value = isNaN(p5) ? 0 : p5;
          const p6Value = isNaN(p6) ? 0 : p6;

          // Extract day of the week (assuming extractDay function is defined)
          const dayOfWeek = extractDay(passedDate);

          return {
            ...student,
            p1: p1Value,
            p2: p2Value,
            p3: p3Value,
            p4: p4Value,
            p5: p5Value,
            p6: p6Value,
            present:
              dayOfWeek !== 6
                ? p1Value + p2Value + p3Value + p4Value + p5Value + p6Value > 0
                : p1Value + p2Value + p3Value + p4Value > 0,
          };
        });

        setAttendanceData(processedData);
        setChartData(generateChartData(processedData));
        setDoughnutData(generateDoughnutData(processedData));
      })
      .catch((error) => {
        console.error("Error fetching data from generalQuery:", error);
      });
  }, [passedDate, refresher]);

  const generateChartData = (data) => {
    const pods = {};
    classData.forEach(({ pod }) => {
      if (!pods[pod]) {
        pods[pod] = { present: 0, total: 0 };
      }
    });

    data.forEach((student) => {
      console.log(classData.find(
        (c) => c.class === student.tempclass
      ))
      const studentPod = classData.find(
        (c) => c.class === student.tempclass
      ).pod;

      if (studentPod) {
        pods[studentPod].total += 1;
        if (student.present) {
          pods[studentPod].present += 1;
        }
      }
    });
    const labels = Object.keys(pods);
    const podsInfo = labels.map((pod) => pods[pod])

    const attendancePercentages = Object.values(pods).map((pod) => {
      return (pod.present / pod.total) * 100 || 0;
    });

    return {
      labels: Object.keys(pods),
      datasets: [
        {
          label: "Attendance Percentage",
          data: attendancePercentages,
          backgroundColor: podColors.slice(0, Object.keys(pods).length), // Assign a unique color to each pod
          borderColor: podColors.slice(0, Object.keys(pods).length),
          borderWidth: 1,
          podsInfo,
        },
      ],
    };
  };

  const generateDoughnutData = (data) => {
    const totalStudents = data.length;
    const presentStudents = data.filter((student) => student.present).length;
    const absentStudents = totalStudents - presentStudents;
    const presentPercentage = ((presentStudents / totalStudents) * 100).toFixed(
      2
    );
    const absentPercentage = ((absentStudents / totalStudents) * 100).toFixed(
      2
    );

    return {
      labels: ["Present", "Absent"],
      datasets: [
        {
          data: [presentStudents, absentStudents],
          backgroundColor: [
            "rgba(75, 192, 192, 0.6)", // Green for Present
            "rgba(255, 99, 132, 0.6)", // Red for Absent
          ],
          borderWidth: 1,
        },
      ],
      total: totalStudents,
      presentPercentage,
      absentPercentage,
    };
  };
  if (attendanceData.length===0) {
    return <div className="container"><CircularProgress /></div>;
  }
  return (
    <div className="attendance-dashboard">
      {/* <div className="date-picker">
        <DatePicker selected={date} onChange={setDate} />
      </div> */}

      <div className="charts-container">
        {chartData ? (
          <div className="attendance-chart">
            <Bar
              data={chartData}
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                    max: 100, // Since it's a percentage
                    title: {
                      display: true,
                      text: "Attendance Percentage (%)",
                    },
                  },
                  x: {
                    title: {
                      display: true,
                      text: "Pod",
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const value = context.raw;
                        const dataset = context.dataset;
                        const podInfo = dataset.podsInfo[context.dataIndex];
                        const totalStudents = podInfo.total;
                        const absentStudents = totalStudents - podInfo.present;

                        return [
                          `Attendance: ${value.toFixed(2)}%`,
                          `Total Students: ${totalStudents}`,
                          `Absent Students: ${absentStudents}`,
                        ];
                      },
                    },
                  },
                },
              }}
            />
          </div>
        ) : (
          ""
        )}

        {doughnutData && (
          <div className="doughnut-chart">
            <Doughnut
              data={doughnutData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                      generateLabels: (chart) => {
                        const labels = chart.data.labels.map(
                          (label, index) => ({
                            text: `${label}: ${
                              chart.data.datasets[0].data[index]
                            }  (${
                              doughnutData[label.toLowerCase() + "Percentage"]
                            }%)`,
                            fillStyle:
                              chart.data.datasets[0].backgroundColor[index],
                          })
                        );
                        labels.push({
                          text: `Total : ${doughnutData.total} Students`,
                          fillStyle: "transparent",
                        });
                        return labels;
                      },
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const label = context.label || "";
                        const value = context.raw || 0;
                        const percentage =
                          context.dataIndex === 0
                            ? doughnutData.presentPercentage
                            : doughnutData.absentPercentage;
                        return `${label}: ${value} students (${percentage}%)`;
                      },
                    },
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceDashboard;
