import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Psychology as PsychologyIcon,
  ReportProblem as ReportProblemIcon,
  Abc as AbcIcon,
  WaterfallChart as WaterfallChartIcon,
  Groups as GroupsIcon,
  Grade as GradeIcon,
  Assessment as AssessmentIcon,
  FormatListNumbered as FormatListNumberedIcon,
  Summarize as SummarizeIcon,
  GpsFixed as GpsFixedIcon,
  CalendarViewMonth as CalendarViewMonthIcon,
  HistoryEdu as HistoryEduIcon,
  PlaylistAddCheckCircle as PlaylistAddCheckCircleIcon,
  Warning as WarningIcon,
  PunchClock as PunchClockIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";

// Importing components
import TeacherMapReport from "./TeacherMapReport";
import AttendanceTeacher from "../views/AttendanceTeacher";
import AttendanceHistory from "./AttendanceHistory";
import CoverageNew from "../CoverageNew";
import AssessmentManager from "./AssessmentManager";
import AttendanceTakingTracker from "./AttendanceTakingTracker";
import ProgressReport from "./ProgressReport";
import EndoFTermReport from "./EndoFTermReport";
import ClassLists from "./classLists/ClassLists";
import Exams from "./Exams";
import StandardsData from "../components/exams/StandardsData";
import StudentsPerformance from "../components/exams/StudentsPerformance";
import Ccss from "../components/curriculum/Ccss";
import SuperadminEndOfYear from "./SuperadminEndOfYear";
import MapAnalysis from "../map/MapAnalysis";
import TimetableNew from "./timetableNew";
import CreditHoursTracker from "../CreditHoursTracker";
import InterventionDashboard from "../InterventionAfterSchool/InterventionDashboard ";
import StudentsAtRisk from "../StudentsAtRisk/StudentsAtRisk";
import BehaviourReporting from "../behaviour/BehaviourReporting";

const menuItems = [
  { label: "MAP Analysis", key: "mapAnalysis", icon: <PsychologyIcon style={{ color: "red", fontSize: "50px" }} /> },
  { label: "Behaviour Management", key: "behaviours", icon: <ReportProblemIcon style={{ color: "darkblue" }} /> },
  { label: "Intervention الدعم", key: "intervention", icon: <AbcIcon style={{ color: "darkblue" }} /> },
  { label: "Data Analysis تحليل البيانات", key: "dataAnalysis", icon: <WaterfallChartIcon style={{ color: "darkblue" }} /> },
  { label: "Attendance الحضور و الغياب", key: "attendance", icon: <GroupsIcon style={{ color: "darkblue" }} /> },
  { label: "Individuals Assess. Data", key: "standardsData", icon: <GradeIcon style={{ color: "darkblue" }} /> },
  { label: "MAP Reports", key: "map", icon: <AssessmentIcon style={{ color: "darkblue" }} /> },
  { label: "Students List قوائم الطلاب", key: "lists", icon: <FormatListNumberedIcon style={{ color: "darkblue" }} /> },
  { label: "End of Term Report", key: "endOfTermReport", icon: <SummarizeIcon style={{ color: "darkblue" }} /> },
  { label: "AttendanceTakingTracker", key: "attendanceTakingTracker", icon: <GpsFixedIcon style={{ color: "darkblue" }} /> },
  { label: "Timetable", key: "timetable", icon: <CalendarViewMonthIcon style={{ color: "darkblue" }} /> },
  { label: "Attendance History سجلات الحضور", key: "attHistory", icon: <HistoryEduIcon style={{ color: "darkblue" }} /> },
  { label: "Assessments Scheduling", key: "scheduleAssessments", icon: <PlaylistAddCheckCircleIcon style={{ color: "darkblue" }} /> },
  { label: "Credit Hours", key: "creditHours", icon: <WarningIcon style={{ color: "darkblue" }} /> },
  { label: "Students at Risk", key: "atrisk", icon: <PunchClockIcon style={{ color: "darkblue" }} /> },
  { label: "Sign out", key: "logout", icon: <LogoutIcon style={{ color: "darkblue" }} /> },
];

const componentMap = {
  mapAnalysis: <MapAnalysis />,
  behaviours: <BehaviourReporting />,
  intervention: <InterventionDashboard />,
  dataAnalysis: <StudentsPerformance />,
  attendance: <AttendanceTeacher />,
  standardsData: <StandardsData />,
  map: <TeacherMapReport />,
  lists: <ClassLists />,
  endOfTermReport: <EndoFTermReport />,
  attendanceTakingTracker: <AttendanceTakingTracker />,
  timetable: <TimetableNew />,
  attHistory: <AttendanceHistory />,
  scheduleAssessments: <Exams />,
  creditHours: <CreditHoursTracker />,
  atrisk: <StudentsAtRisk />,
};

const TeacherNew = () => {
  const [state, setState] = useState({ left: false });
  const [switcher, setSwitcher] = useState("timetable");

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) return;
    setState({ ...state, [anchor]: open });
  };

  const handleMenuClick = (key) => {
    if (key === "logout") {
      sessionStorage.removeItem("user");
      window.location.reload();
    } else {
      setSwitcher(key);
    }
  };

  return (
    <div>
      <React.Fragment>
        <MenuIcon onClick={toggleDrawer("left", true)} fontSize="large" />
        <Drawer anchor="left" open={state.left} onClose={toggleDrawer("left", false)}>
          <Box role="presentation" onClick={toggleDrawer("left", false)} onKeyDown={toggleDrawer("left", false)}>
            <List>
              {menuItems.map(({ label, key, icon }) => (
                <ListItem key={key} disablePadding>
                  <ListItemButton onClick={() => handleMenuClick(key)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </React.Fragment>
      <div className="container">{componentMap[switcher] || null}</div>
    </div>
  );
};

export default TeacherNew;
