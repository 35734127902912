import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSVLink } from "react-csv";
import { Button } from "@material-ui/core";
import "./attendanceTeacher.css";
import generalQuery from "../generalQuery";
const AttendanceTeacher = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [list, setList] = useState([]);
  const [listTocompare, setListTocompare] = useState([]);
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState("");
  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [subjects, setSubjects] = useState([
    "ARA",
    "ARA_INTV",
    "ART",
    "EL1_Sports 1",
    "EL1_Sports 2",
    "EL1_Sports 3",
    "EL2_DRM2",
    "EL2_DRM3",
    "EL2_MUS1",
    "EL2_MUS2",
    "EL3_DRM1",
    "EL3_ICT",
    "EL3_ROBO",
    "EL3_VART",
    "ENG",
    "ENG_INTV",
    "ICT",
    "ISL",
    "MOR",
    "MTH",
    "MTH_INTV",
    "MUS",
    "PEH",
    "SCI",
    "SCI_INTV",
    "SOC",
    "SWM",
  ]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [time, setTime] = useState(
    new Date().getHours() * 100 + new Date().getMinutes()
  );
  const [showWeekend, setShowWeekend] = useState(false);
  const [grade, setGrade] = useState("");
  const [indEsis, setIndEsis] = useState("");
  const tablename =
    "zatt" +
    new Date().toISOString().slice(0, 10).replace("-", "").replace("-", "");
  const headers = [
    { label: "esis", key: "pupilnumber" },
    { label: "Name", key: "studentname" },
    { label: "الأسم", key: "arabicname" },
    { label: "Status", key: "selectedperiod" },
  ];
  const getClasses = async () => {
    generalQuery(`select distinct(tempclass) from ${tablename}`).then(
      (results) => {
        let arr = [];
        results.forEach((r) => {
          arr.push(r.tempclass);
        });
        setClasses(arr.sort((a, b) => (a > b ? 1 : -1)));
      }
    );
  };

  const getAttendancebyPeriod = async (x, y) => {
    setLoading(true);

    generalQuery(
      //       selectedSubject === "eng_intv" || selectedSubject === "eng"
      //         ? `SELECT
      //     ${tablename}.studentname,
      //     ${tablename}.leveling,
      //     ${tablename}.pupilnumber,
      //     ${tablename}.amabsentlate,
      //     ${tablename}.amreason,
      //     ${tablename}.pmabsentlate,
      //     ${tablename}.pmreason,
      //     ${tablename}.attendancemode,
      //     ${tablename}.attendancepattern,
      //     ${tablename}.homeroomid,
      //     ${tablename}.homeroomcode,
      //     ${tablename}.grade,
      //     ${tablename}.groupcode,
      //     ${tablename}.arabicname,
      //     ${tablename}.tempclass,
      //     ${y} as selectedPeriod,
      //     ${tablename}.status,
      //     student_growth_data.classgroup
      // FROM
      //     ${tablename}
      // JOIN
      //     student_growth_data
      // ON
      //     ${tablename}.pupilnumber = student_growth_data.studentid
      // AND
      //     student_growth_data.subject = 'Reading'
      // WHERE
      //     ${tablename}.tempclass = '${x}'
      // ORDER BY
      //     ${tablename}.arabicname;
      // `
      //         :
      `SELECT studentname,leveling,pupilnumber,amabsentlate,amreason,pmabsentlate,pmreason,attendancemode,attendancepattern,homeroomid,homeroomcode,grade,groupcode,arabicname,tempclass,${y} as selectedPeriod,status,leveling  FROM ${tablename} where tempclass='${x}'order by arabicname`
    ).then((resp) => {
      let originalAtt = [];
      setList(resp);
      resp.forEach((e) => {
        originalAtt.push({
          pupilnumber: e.pupilnumber,
          att: e.selectedperiod,
        });
        // if (e.selectedperiod === "0") {
        //   e.selectedperiod = "Absent غائب";
        // }
        // if (e.selectedperiod === "1") {
        //   e.selectedperiod = "Present حاضر";
        // }
        // if (Number(e.selectedperiod) > 0 && Number(e.selectedperiod) === 1) {
        //   e.selectedperiod = "Late متأخر";
        // }
      });
      setListTocompare(originalAtt);
      setData(resp);
      setLoading(false);
    });
  };

  const createattendancetable = async () => {
    try {
      const body = {
        tablename,
      };
      const response = await fetch(
        "https://mut.herokuapp.com/createattendancetable",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const resp = await response.json();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (
      new Date().getDay() === 1 ||
      new Date().getDay() === 2 ||
      new Date().getDay() === 3 ||
      new Date().getDay() === 4 ||
      new Date().getDay() === 5
    ) {
      createattendancetable();
    } else {
      setShowWeekend(true);
    }
    getClasses();
    setTime(new Date().getHours() * 100 + new Date().getMinutes());
  }, []);
  function reformatString(str) {
    const match = str.match(/G(\d+)-([A-Z])/);
    if (match) {
      return `${match[1]}${match[2]}`;
    }
    return null;
  }
  if (showWeekend) {
    return (
      <div>
        <h3>Happy Weekend!</h3>
      </div>
    );
  }

  const updateAttendance = (arr, period, selectedClass) => {
    let casesPeriod = [];
    let casesConf = [];
    let casesSub = [];
    let casesCreditHours = [];
    let pupilNumbers = [];
    let creditHourPupilNumbers = [];

    arr.forEach((a) => {
      casesPeriod.push(`WHEN pupilnumber='${a[0]}' THEN '${a[1]}'`);
      casesConf.push(`WHEN pupilnumber='${a[0]}' THEN 'taken'`);
      casesSub.push(`WHEN pupilnumber='${a[0]}' THEN '${selectedSubject}'`);
      pupilNumbers.push(`'${a[0]}'`);

      let prevAtt = listTocompare.filter((b) => b.pupilnumber === a[0])[0][
        "att"
      ];
      if ((prevAtt === "null" || prevAtt === "0") && a[1] === "1") {
        casesCreditHours.push(
          `WHEN pupilnumber='${a[0]}' THEN ${selectedSubject} + 1`
        );
        creditHourPupilNumbers.push(`'${a[0]}'`);
      }
      if ((prevAtt === "1" || prevAtt === "0.5") && a[1] === "0") {
        casesCreditHours.push(
          `WHEN pupilnumber='${a[0]}' THEN ${selectedSubject} - 1`
        );
        creditHourPupilNumbers.push(`'${a[0]}'`);
      }
    });

    const updateTablenameQuery = `
      UPDATE ${tablename}
      SET 
        ${period} = CASE ${casesPeriod.join(" ")} END,
        ${period + "conf"} = CASE ${casesConf.join(" ")} END,
        ${period + "sub"} = CASE ${casesSub.join(" ")} END
      WHERE pupilnumber IN (${pupilNumbers.join(", ")})
    `;

    const updateCreditHoursQuery = `UPDATE credithours SET ${selectedSubject.toLocaleLowerCase()} = CASE ${casesCreditHours.join(
      " "
    )} END WHERE pupilnumber IN (${creditHourPupilNumbers.join(", ")})`;

    generalQuery(updateTablenameQuery).then(() => {
      if (creditHourPupilNumbers.length > 0) {
        generalQuery(updateCreditHoursQuery);
      }
    });
    getAttendancebyPeriod(selectedClass, period);
  };
  return (
    <div style={{ textAlign: "center" }}>
      {time > 700 && time < 1530 ? (
        <>
          <div>
            {period !== "" && (
              <CSVLink
                data={data}
                headers={headers}
                filename={
                  new Date().toISOString().slice(0, 10) +
                  "--" +
                  period +
                  "--" +
                  selectedClass +
                  ".csv"
                }
              >
                Download Attendance تنزيل حضور الحصة
              </CSVLink>
            )}
            {list.length > 0 && (
              <div className="statsTable">
                <table>
                  <thead>
                    <tr>
                      <th>Total Number</th>
                      <th># of Absent Students الغياب</th>
                      <th># of Present Students الحضور</th>
                      <th>Attendance Ratio نسبة الحضور</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{list.length}</td>
                      <td>
                        {
                          list.filter((d) => d.selectedperiod === "0")
                            .length
                        }
                      </td>
                      <td>
                        {Number(list.length) -
                          Number(
                            list.filter(
                              (d) => d.selectedperiod === "0"
                            ).length
                          )}
                      </td>
                      <td>
                        {Math.trunc(
                          ((Number(list.length) -
                            Number(
                              list.filter(
                                (d) => d.selectedperiod === "0"
                              ).length
                            )) *
                            100) /
                            Number(list.length)
                        ) + "%"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <select
            className="select"
            onChange={(e) => {
              setList([]);
              setSelectedClass(e.target.value);
              setPeriod("");
              if (document.getElementById("periodSelect")) {
                document.getElementById("periodSelect").value = "";
              }
              generalQuery(
                `select * from subjectsummary where class='${reformatString(
                  e.target.value
                )}'`
              ).then((results) => {
                let obj = results[0];
                setGrade(
                  Array.from(new Set(Object.values(obj)))[0]
                    .match(/\d+/g)
                    .map(Number)[0]
                );
              });
            }}
          >
            <option value="">Select Class...اختر الشعبة الصفية</option>
            {classes.map((c) => {
              return <option key={c}>{c}</option>;
            })}
          </select>
          <select
            className="select"
            onChange={(e) => {
              setSelectedSubject(e.target.value);
            }}
          >
            <option>----Subject المادة---</option>
            <option value="ara">ARA</option>
            <option value="ara_intv">ARA-INTV</option>
            <option value="art">ART</option>
            <option value="el1_sports_1">EL1-Sports 1</option>
            <option value="el1_sports_2">EL1-Sports 2</option>
            <option value="el1_sports_3">EL1-Sports 3</option>
            <option value="el2_drm2">EL2-DRM2</option>
            <option value="el2_drm3">EL2-DRM3</option>
            <option value="el2_mus1">EL2-MUS1</option>
            <option value="el2_mus2">EL2-MUS2</option>
            <option value="el3_drm1">EL3-DRM1</option>
            <option value="el3_ict">EL3-ICT</option>
            <option value="el3_robo">EL3-ROBO</option>
            <option value="el3_vart">EL3-VART</option>
            <option value="eng">ENG</option>
            <option value="eng_intv">ENG-INTV</option>
            <option value="ict">ICT</option>
            <option value="isl">ISL</option>
            <option value="mor">MOR</option>
            <option value="mth">MTH</option>
            <option value="mth_intv">MTH-INTV</option>
            <option value="mus">MUS</option>
            <option value="peh">PEH</option>
            <option value="sci">SCI</option>
            <option value="sci_intv">SCI-INTV</option>
            <option value="soc">SOC</option>
            <option value="swm">SWM</option>
          </select>
          {selectedSubject && (
            <select
              id="periodSelect"
              className="select"
              style={{ margin: "20px", height: "40px" }}
              onChange={(e) => {
                setPeriod(e.target.value);
                getAttendancebyPeriod(selectedClass, e.target.value);
                setShowTable(true);
              }}
            >
              <option value="">Period...الحصة</option>
              <option value="p1">Period 1 الحصة </option>
              <option value="p2">Period 2 الحصة </option>
              <option value="p3">Period 3 الحصة </option>
              <option value="p4">Period 4 الحصة </option>
              <option value="p5">Period 5 الحصة </option>
              <option value="p6">Period 6 الحصة </option>
            </select>
          )}
        </>
      ) : (
        "No Running Lessons لا توجد حصص حاليا"
      )}
      {loading ? (
        <div style={{ marginTop: "15%" }}>
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{ display: "grid", placeItems: "center", overflow: "auto" }}
        >
          {list.length > 0 && (
            <div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "30%", marginBottom: "15px" }}
                  onClick={() => {
                    let arr = [];
                    list.forEach((l) => {
                      if (l.selectedperiod === "Present حاضر") {
                        arr.push([l.pupilnumber, "1"]);
                      } else if (l.selectedperiod === "Absent غائب") {
                        arr.push([l.pupilnumber, "0"]);
                      } else {
                        arr.push([l.pupilnumber, l.selectedperiod]);
                      }
                    });
                    updateAttendance(arr, period, selectedClass);
                  }}
                >
                  Save
                </Button>
                {/* <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    console.log("list", list);
                    console.log("listToCompare", listTocompare);
                  }}
                >
                  Save Without Adding Credit Hours
                </Button> */}
                <div>
                  <input
                    type="number"
                    placeholder="eSIS"
                    className="select"
                    onChange={(e) => {
                      setIndEsis(e.target.value);
                    }}
                  ></input>
                  <button
                    className="btn"
                    onClick={() => {
                      generalQuery(
                        `SELECT studentname,leveling,pupilnumber,amabsentlate,amreason,pmabsentlate,pmreason,attendancemode,attendancepattern,homeroomid,homeroomcode,grade,groupcode,arabicname,tempclass,${period} as selectedPeriod,status  FROM ${tablename} where pupilnumber='${indEsis}'`
                      ).then((resp) => {
                        resp.forEach((e) => {
                          if (e.selectedperiod === "0") {
                            e.selectedperiod = "Absent غائب";
                          }
                          if (e.selectedperiod === "1") {
                            e.selectedperiod = "Present حاضر";
                          }
                          if (
                            Number(e.selectedperiod) > 0 &&
                            Number(e.selectedperiod) === 1
                          ) {
                            e.selectedperiod = "Late متأخر";
                          }
                        });
                        setList((list) => [...list, resp[0]]);
                      });
                    }}
                  >
                    Add Student
                  </button>
                  <span className="red">
                    {" "}
                    *To add a Student from another class
                  </span>
                </div>
                {/* marking all attending or all absent */}
                <div
                  style={{
                    marginTop: "15px",
                    fontWeight: "bold",
                    marginBottom: "25px",
                    backgroundColor: "#e6f5ff",
                    padding: "5px",
                  }}
                  onChange={(e) => {
                    const newStatus = e.target.value;
                    setList((prevList) =>
                      prevList.map((item) => ({
                        ...item,
                        selectedperiod:
                          newStatus === "Present حاضر"
                            ? "1"
                            : newStatus === "Absent غائب"
                            ? "0"
                            : "",
                      }))
                    );
                  }}
                >
                  <input type="radio" value="Present حاضر" name="att" /> All
                  Present الكل حاضر
                  <input
                    type="radio"
                    value="Absent غائب"
                    name="att"
                    style={{ marginLeft: "15px" }}
                  />
                  All Absent الكل غائب
                </div>
              </div>
              <table className="coverageTable">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  {list.map((l) => {
          
                    return (
                      <tr key={l.pupilnumber}>
                        <td>{list.indexOf(l) + 1}</td>
                        <td style={{ textAlign: "right" }}>{l.pupilnumber}</td>
                        <td style={{ textAlign: "left" }}>{l.studentname}</td>
                        <td style={{ textAlign: "left" }}>
                          {l[period + "sub"]}
                        </td>
                        {/* <td
                          style={{
                            backgroundColor: `${
                              l.leveling !== "null"
                                ? `${
                                    l.leveling === "Advanced" ||
                                    l.leveling === "Proficient"
                                      ? "gold"
                                      : "Thistle"
                                  }`
                                : ""
                            }`,
                          }}
                        >
                          {l.leveling !== "null" ? l.leveling : ""}
                        </td> */}
                        {selectedSubject === "eng_intv" ||
                        selectedSubject === "eng" ? (
                          <td
                            style={{
                              backgroundColor: `${
                                l.leveling !== "#ERROR_#N/A" ? "gold" : ""
                              }`,
                            }}
                          >
                            {l.leveling !== "#ERROR_#N/A" && l.leveling}
                          </td>
                        ) : (
                          ""
                        )}
                        <td style={{ textAlign: "right" }}>{l.arabicname}</td>{" "}
                        <td style={{ textAlign: "right" }}>{l.tempclass}</td>
                        <td>
                          <div
                            className={`attButton ${
                              l.selectedperiod === "1"
                                ? "aquamarine"
                                : l.selectedperiod === "0"
                                ? "plum"
                                : l.selectedperiod === "0.5"
                                ? "yellow"
                                : ""
                            }`}
                            onClick={() => {
                              setList((prevList) =>
                                prevList.map((item, idx) => {
                                  if (idx === list.indexOf(l)) {
                                    // Update selectedperiod and return updated item
                                    const nextState =
                                      item.selectedperiod === "1"
                                        ? "0"
                                        : item.selectedperiod === "0"
                                        ? "0.5"
                                        : "1";
                                    return {
                                      ...item,
                                      selectedperiod: nextState,
                                    };
                                  }
                                  return item;
                                })
                              );
                            }}
                          >
                            {l.selectedperiod === "1" ? (
                              <div className="aquamarine">Present حاضر</div>
                            ) : l.selectedperiod === "0" ? (
                              <div className="plum">Absent غائب</div>
                            ) : l.selectedperiod === "0.5" ? (
                              <div className="yellow">Late متأخر</div>
                            ) : (
                              <div className="">Present/Absent</div>
                         
                            )}
                          </div>
                        </td>
                        <td id={"td" + list.indexOf(l)}>
                          {l.selectedperiod === "1"
                            ? "✓"
                            : l.selectedperiod === "0"
                            ? "🚫"
                            : l.selectedperiod === "0.5"
                            ? "🕑"
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AttendanceTeacher;
