const classes = [
  "G10-A",
  "G10-B",
  "G10-C",
  "G10-D",
  "G10-E",
  "G10-F",
  "G10-G",
  "G10-H",
  "G11-A",
  "G11-B",
  "G11-C",
  "G11-D",
  "G11-E",
  "G11-F",
  "G8-A",
  "G8-B",
  "G8-C",
  "G8-D",
  "G8-E",
  "G8-F",
  "G8-G",
  "G8-H",
  "G8-I",
  "G8-J",
  "G9-A",
  "G9-B",
  "G9-C",
  "G9-D",
  "G9-E",
  "G9-F",
  "G9-G",
  "G9-H",
  "G9-I",
  "G9-J",
  "G9-K",
];
export default classes